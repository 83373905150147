import React, { useState, useEffect, forwardRef, InputHTMLAttributes, useCallback } from 'react';
import { Button, Card, CardBody, Col, Container, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Nav, Row, UncontrolledTooltip } from 'reactstrap';
import { ChevronLeft, ChevronRight, Download, Eye, Trash2 } from 'react-feather';
import Swal from 'sweetalert2';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import { Link } from 'react-router-dom';
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import DataTable from 'react-data-table-component';
import { saveAs } from 'file-saver'
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../ReaduxToolkit/Hooks';
import ReactPaginate from 'react-paginate';
import { getData, Get_permission } from './store';
import moment from "moment";
import websocket_webservice from '../../../Service/websocket_webservice'


const CustomHeader = ({ menuActions, handleFilter, value, handlePerPage, rowsPerPage }: any) => {
  return (
    <div className='client-master-list-table-header w-100 py-2'>
      <Row>
        <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
          <div className='d-flex align-items-center me-4 '>
            <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
            <Input
              type='select'
              id='rows-per-page'
              value={rowsPerPage}
              onChange={handlePerPage}
              className='form-control ms-50 larger-select '
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
            </Input>
          </div>
          {menuActions.includes('add') && (
            <Button tag={Link} to='/templateCreation/newTemplateCreation' color='primary' className='ms-2 zoom-on-hover'>
              + Create New Template
            </Button>
          )}
        </Col>
        <Col
          lg='6'
          className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-3'
        >
          <InputGroup className="input-group-search mb-2 zoom-on-hover">
            <Input
              type="text"
              placeholder="🔍 Search...."
              value={value}
              onChange={(e) => handleFilter(e.target.value)}
              className="input-search"
            />
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};

const TemplateCreation: React.FC = () => {
  interface DeleteRowData {
    id: string;
    User_Name: string;
    ClientName: string;
    file_name: string;
    refid: string;
    content_time: string;
    UserName: string;
  }


  const [data, setData] = useState<DeleteRowData[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const usertype = localStorage.UserType;
  const id = usertype === 'MOS' ? localStorage.id : localStorage.ClientId;

  const store = useSelector((state: any) => state.Template)


  const [value, setValue] = useState<string>('');
  const [sort, setSort] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<any>('short_the_date'); // Example initial sort column
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  // const menuActions = ['add', 'delete'];
  const dispatch = useAppDispatch()
  const [eventId, setEventId] = useState<DeleteRowData[]>([]);
  const [eventId1, setEventId1] = useState(0);
  const selectedRowsPerPage: { [key: number]: DeleteRowData[] } = {};
  const action = { fromUser: true };
  const [scrollInnerModal, setScrollInnerModal] = useState<boolean>(false);
  const [refid, setRefid] = useState<string | null>(null);
  const [filteredAuditData, setFilteredAuditData] = useState<any[]>([]);

  const [menuActions, setMenuActions] = useState<string>('');

  
  useEffect(() => {
    if (localStorage.id !== null) {
      dispatch(Get_permission(localStorage.id)).then((response) => {
        const res1 = response.payload;
        const Action = res1[0].Permission.filter(
          (item: any) => item.MenuKey === "TEMPLATECREATION"
        );
        setMenuActions(Action[0].MenuActions);
      });
    }
  }, [localStorage.id])



  // const fetchData = async () => {
  //   const paramData = { id, usertype };
  //   const EncParams = AesEncrypt(JSON.stringify(paramData));;
  //   try {
  //     const response = await fetch(webservice + 'api/TemplateCreation/get_uploaded_TemplateData', {
  //       method: 'POST',
  //       body: JSON.stringify({ EncParams }),
  //       headers: {
  //         'Content-type': 'application/json; charset=UTF-8',
  //       },
  //     })
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch data');
  //     }
  //     const result = await response.json();
  //     // Assuming AesDecrypt is defined and decrypts the result properly
  //     const decryptedResult = AesDecrypt(result);
  //     if (decryptedResult.message === 'There is no Record Found') {
  //       setData([]);
  //     } else {
  //       setData(decryptedResult.data);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // useEffect(() => {
  //   dispatch(
  //     getData({
  //       q: value,
  //       perPage: rowsPerPage,
  //       page: currentPage,
  //       sort,
  //       sortColumn
  //     })
  //   );
  // }, [dispatch, store.data.length, value, rowsPerPage, currentPage, sortColumn]);

  useEffect(() => {
    // Fetch initial data
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn
      })
    );

    // WebSocket setup
    const socket = new WebSocket(`${websocket_webservice}ws/Tempalatedata/`);

    // socket.onopen = () => console.log('WebSocket connected');
    // socket.onclose = () => console.log('WebSocket closed for data updates');
    // socket.onerror = (error) => console.error('WebSocket error:', error);

    socket.onmessage = () => {
      // When a WebSocket message is received, refetch the data
      dispatch(
        getData({
          q: value,
          perPage: rowsPerPage,
          page: currentPage,
          sort,
          sortColumn
        })
      );
    };

    // Cleanup WebSocket connection when the component unmounts
    return () => {
      if (socket.readyState === 1) { // <-- This is important
        socket.close();
      }
    }
  }, [dispatch, store.data.length, value, rowsPerPage, currentPage, sortColumn]);



  const handleReloadTable = () => {
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn
      })
    );
  };

  const handleFilter = (val: any) => {
    setValue(val)
    setCurrentPage(1)
    dispatch(
      getData({
        q: val,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn,
      })
    )
  }

  const handlePerPage = (e: any) => {
    dispatch(
      getData({
        q: value,
        perPage: parseInt(e.target.value),
        page: currentPage,
        sort,
        sortColumn,
      })
    )
    setRowsPerPage(parseInt(e.target.value))
  }

  const handlePagination = (page: any) => {
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: page.selected + 1,
        sort,
        sortColumn,
      })
    )
    setCurrentPage(page.selected + 1)
  }

  const dataToRender = () => {
    const filters: any = {
      q: value

    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && isFiltered) {
      return []
    } else {
      return store.allData.slice(0, rowsPerPage)
    }
  }

  const handleSort = (column: any, sortDirection: any) => {
    setSort(sortDirection)
    setSortColumn(column.sortField)
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort: sortDirection,
        sortColumn: column.sortField
      })
    )
  }

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage))

    return (
      <ReactPaginate
        previousLabel={<ChevronLeft size={16} />}
        nextLabel={<ChevronRight size={16} />}
        breakLabel="..."
        pageCount={count || 1}
        onPageChange={handlePagination}
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        containerClassName="pagination-container"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
    )
  }
  const getAuditlogdata = async (data: any) => {
    const EncParams = AesEncrypt(data);
    const response = await fetch(webservice + 'api/TemplateCreation/getAuditLogData', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);
    return TempData;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (refid !== null) {
        const res = await getAuditlogdata(refid);

        setFilteredAuditData(res);
      }
    };
    fetchData();
  }, [refid]);
  const DownloadAuditLogdata = async (Data: any) => {
    // const EncParams = AesEncrypt(Data);
    await fetch(webservice + 'api/TemplateCreation/DownloadAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ Data }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
  };
  const DownloadTemplate = async (row: any) => {

    const Id = row.id;
    const Username = localStorage.UserName;
    const Name = row.file_name;
    const RefId = row.refid;
    const Data = { Username, RefId, Name };
    await DownloadAuditLogdata(Data);
    const EncParams = AesEncrypt(JSON.stringify(Id));
    try {
      const response = await fetch(webservice + 'api/TemplateCreation/GetPreviewData', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const result = await response.json();
      const decryptedResult = AesDecrypt(result);
      const FileName = decryptedResult[0].file_name
      const docxData = Uint8Array.from(atob(decryptedResult[0].encoded_doc_content), (c) => c.charCodeAt(0));
      const blob = new Blob([docxData], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      saveAs(blob, FileName + '.docx')
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const deleteTemplate = async (Id: any) => {


    const EncParams = AesEncrypt(Id);
    await fetch(webservice + 'api/TemplateCreation/delete', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
  };
  const DeleteAuditLogdata = async (Data: any) => {

    // const EncParams = AesEncrypt(Data);
    await fetch(webservice + 'api/TemplateCreation/deleteauditlog', {
      method: 'POST',
      body: JSON.stringify({ Data }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
  }
  const DeleteRow = async (rows: DeleteRowData[] | DeleteRowData) => {
    const Username = localStorage.UserName;
    // const Name = row.UserName;
    // const RefId = row.id;
    // const Data = { Username, RefId, Name };
    // const Id = row.id;
    const deleteRow = async () => {
      const rowCount = Array.isArray(rows) ? rows.length : 1;

      Swal.fire({
        title: "Insurance Verification Platform",
        text: "Are you sure do you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ms-1",
        },
        buttonsStyling: false,
        allowOutsideClick: false
      })
        .then(async function (result) {
          if (result.isConfirmed) {
            if (Array.isArray(rows)) {
              const ids = rows.map(row => row.id);
              const Data = rows.map(row => ({
                Username,
                RefId: row.refid,
                Name: row.file_name
              }));
              await deleteTemplate(ids);
              await DeleteAuditLogdata(Data);
            } else {
              const { id, UserName: Name } = rows;
              const RefId = rows.refid
              const name = rows.file_name
              const Data = { Username, name, RefId };

              await deleteTemplate([id]);
              await DeleteAuditLogdata(Data);
            }
            // await deleteTemplate(Id);

            Swal.fire({
              icon: "success",
              title: "Insurance Verification Platform",
              text: "Template has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
              allowOutsideClick: false
            });
            // fetchData();
            handleReloadTable()
          }
        });
    };
    deleteRow();
  };


  const filteredData = data.filter(row => {
    return Object.values(row).some(val =>
      typeof val === "string" && val.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  interface BootstrapCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
  }
  // Handle individual row checkbox change
  const BootstrapCheckbox = forwardRef<HTMLInputElement, BootstrapCheckboxProps>(
    ({ id, ...props }, ref) => (
      <div style={{ position: 'relative', left: '10px' }}>
        <input type='checkbox' id={id} ref={ref} {...props} />
      </div>
    )
  );

  const handleChangecheck = useCallback(
    ({ selectedRows }: any) => {


      const selectedEventIds = [];
      selectedRows.forEach((row: any) => {
        selectedEventIds.push(row.id);
      });
      setEventId(selectedRows)
      setEventId1(selectedEventIds.length)
      if (!action.fromUser)
        return;
      selectedRowsPerPage[currentPage] = selectedRows;
    }, [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const CustomLoadingComponent = () => {
    return (
      <div className="">
        {store.no_Data === 'There is no Record Found' ? (
          <h4>
            <br/>
            <p>There are no records to display</p>
          </h4>
        ) : (
          <div className="custom-loader">
            <label className="custom-label">Please wait...</label>
            <div className="custom-loading"></div>
          </div>
        )}
      </div>
    );
  };



  const Columns = [
    {
      name: usertype === "MOS" ? 'Client Name' : 'User Name',
      selector: (row: DeleteRowData) => row.ClientName,
      sortable: true,
    },
    {
      name: "File Name",
      selector: (row: DeleteRowData) => row.file_name,
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row: DeleteRowData) => row.User_Name,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row: DeleteRowData) => row.content_time,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: DeleteRowData) => (
        <>
          <Eye
            size={17}
            color='#09d5ff'
            onClick={() => {
              setScrollInnerModal(true);
              setRefid(row.refid);
            }}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          <Download
            size={17}
            id='positionLeft'
            color='rgb(53 88 116)'
            onClick={() => { DownloadTemplate(row) }}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          <UncontrolledTooltip placement='top' target='positionLeft' style={{ backgroundColor: "#355876", color: "white" }}>
            Download Template In Word
          </UncontrolledTooltip>
          {/* <Edit
            size={17}
            color='#FFA500'
            onClick={() => {
              navigate(`/templateCreation/EditTemplate/${row.id}`)
            }}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          /> */}
          {eventId1 === 0 && (
            menuActions.includes("delete") && (
              <>
                <Trash2
                  size={17}
                  id='Delete'
                  color='#FF0000'
                  onClick={() => { DeleteRow(row) }}
                  style={{ cursor: 'pointer' }}
                />
                <UncontrolledTooltip placement='top' target='Delete' style={{ backgroundColor: "#355876", color: "white" }}>
                  Delete
                </UncontrolledTooltip>
              </>
            )
          )}
        </>
      ),
    },
  ];
  const customStyles = {
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f0f0',

        },
      },
    },
    headCells: {
      style: {
        backgroundColor: '#355876',  // Change background color of headers
        color: 'white',  // Change text color of headers
        fontSize: '16px',  // Adjust font size of headers
      },
    },
  };

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, store.total);



  return (
    <>
      <Breadcrumbs mainTitle='Template Creation' title='Template Creation' />
      <Container fluid>
        <Row >
          <Col sm={12}>
            {/* <Row>
              <Col sm={6}>
                <Row className="align-items-center">
                  <Col sm={8}>
                    <Link className="link" to={`/templateCreation/newTemplateCreation`} style={{ textDecoration: 'none' }}>
                      <Button color="primary" className="align-middle zoom-on-hover">+ Create New Templatre</Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <InputGroup className="input-group-search mb-2 zoom-on-hover">
                  <Input
                    type="text"
                    placeholder="🔍 Search...."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="input-search"
                  />
                </InputGroup>

              </Col>
            </Row> */}

            <br />
            <Card >
              <Modal style={{ maxWidth: '1500px', maxHeight: '600px' }} isOpen={scrollInnerModal} toggle={() => setScrollInnerModal(!scrollInnerModal)} className='modal-dialog-centered modal-lg'>
                <ModalHeader toggle={() => setScrollInnerModal(!scrollInnerModal)}>Template creation  Audit Log</ModalHeader>
                <ModalBody>
                  <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                      <thead>
                        <tr style={{ backgroundColor: '#f2f2f2' }}>
                          <th className="audit-tableHeaderStyle">Date & Time</th>
                          <th className="audit-tableHeaderStyle">Action</th>
                          <th className="audit-tableHeaderStyle">Data Set</th>
                          <th className="audit-tableHeaderStyle">Changed By</th>
                        </tr>
                      </thead>
                      <tbody style={{ alignItems: 'center' }}>
                        {filteredAuditData &&
                          filteredAuditData
                            .sort((a: any, b: any) => new Date(b.Modified).getTime() - new Date(a.Modified).getTime())
                            .map((item: any, index: number) => (
                              <tr key={index}>
                                <td className="audit-tableDataStyle">
                                  {(() => {
                                    const localDate = moment.utc(item.Modified).local().format('MM/DD/YYYY hh:mm:ss A');
                                    return localDate;
                                  })()}
                                </td>
                                <td className="audit-tableDataStyle">{item.Action}</td>
                                <td className="audit-tableDataStyle">
                                  {item.Action === 'VIEW' ? (
                                    <span>{item.JsonData}</span>
                                  ) : item.Action === 'DOWNLOAD' ? (
                                    <span>{item.JsonData}</span>
                                  ) : (
                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                      <thead>
                                        <tr style={{ backgroundColor: '#f2f2f2' }}>
                                          <th className="audit-tableHeaderStyle">Field</th>
                                          {item.Action === 'EDIT' && (
                                            <>
                                              <th className="audit-tableHeaderStyle">Before</th>
                                              <th className="audit-tableHeaderStyle">After</th>
                                            </>
                                          )}
                                          {item.Action === 'ADD' && (
                                            <th className="audit-tableHeaderStyle">Value</th>
                                          )}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.JsonData
                                          .sort((a: any, b: any) => a.field.localeCompare(b.field))
                                          .map((dataItem: any, dataIndex: number) => (
                                            <tr key={dataIndex}>
                                              <td className="audit-tableDataStyle">{dataItem.field}</td>
                                            
                                              {item.Action === 'ADD' && (
                                                <td className="audit-tableDataStyle">
                                                  {dataItem.field === 'Password' || dataItem.field === 'Pin' ? (
                                                    '******'
                                                  ) : dataItem.field === 'Permission' && Array.isArray(dataItem.value) ? (
                                                    <table style={{ width: '100%' }}>
                                                      <thead>
                                                        <tr>
                                                          <th className="audit-tableHeaderStyle">Menu</th>
                                                          <th className="audit-tableHeaderStyle">Actions</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                      </tbody>
                                                    </table>
                                                    ) : dataItem.field === 'KEYS' && Array.isArray(dataItem.value) ? (
                                                      // Iterate over KEYS array and display each value one by one
                                                      <div>
                                                        {dataItem.value.map((keyValue: string, keyIndex: number) => (
                                                          <div key={keyIndex}>{keyValue}</div>
                                                        ))}
                                                      </div>
                                                    ) :  (
                                                    (dataItem.value && dataItem.value.value) || dataItem.value
                                                  )}
                                                </td>
                                              )}
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  )}
                                </td>
                                <td className="audit-tableDataStyle">{item.UserName}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </ModalBody>
              </Modal>
              <CardBody>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">

                    <DataTable
                    persistTableHead
                      subHeader={true}
                      responsive={true}
                      onSort={handleSort}
                      data={dataToRender()}
                      columns={Columns}
                      paginationServer
                      customStyles={customStyles}
                      paginationPerPage={rowsPerPage}
                      paginationDefaultPage={currentPage}
                      // clearSelectedRows={loading}
                      selectableRows
                      selectableRowsHighlight
                      selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                      onSelectedRowsChange={handleChangecheck}
                      paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                      noDataComponent={<CustomLoadingComponent />}
                      paginationComponent={CustomPagination}
                      subHeaderComponent={
                        <CustomHeader
                          value={value}
                          menuActions={menuActions}
                          rowsPerPage={rowsPerPage}
                          handleFilter={handleFilter}
                          handlePerPage={handlePerPage}

                        />
                      }
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ paddingLeft: '2%' }}>
                        {store.total && (
                          <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                        )}
                      </div>
                      <div>
                        <CustomPagination />
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div>
              {eventId1 > 0 && menuActions.includes('delete') && (
                <div className="customizer-links open">
                  <Nav className=" flex-column nav-pills">
                    <a className="nav-link" onClick={() => DeleteRow(eventId)}>
                      <span>Delete</span>
                      <Trash2 className="trash-icon" size={20} />
                    </a>
                  </Nav>
                </div>
              )}
            </div>
          </Col>
        </Row >
      </Container >
    </>

  );
};
export default TemplateCreation;